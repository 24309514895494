$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Form control
 -  (<input class="form-control" type="textarea" />)
 */


textarea {
	&.is-invalid {
		padding-inline-end: $input-padding-x;
		background: none;
	}
}