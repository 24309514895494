$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Main Content
 */


.header {
	display: none;

	// Media queries
	@include media-breakpoint-up(sm) {
		padding: logical ( $spacer * .5 ) 0 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.content {
	display: flex;
	flex-direction: column;
	padding-block-start: calc(#{$main-nav-height-mobile} + #{$spacer});

	&-fullscreen {
		min-height: calc(100vh);
		justify-content: center;
	}

	// Media queries
	@include media-breakpoint-up(sm) {
		padding-block-start: 0;
		padding-inline-start: $main-nav-width-tablet;
		margin: logical 0 ( $grid-gutter-width * .5 );
	}
	@include media-breakpoint-up(lg) {
		min-height: calc( 100vh - #{$footer-height} );
		padding-inline-start: $main-nav-width;
		padding-block-end: $footer-height;
	}

	&-inner {
		box-shadow: inset $box-shadow-basic-inset;
		min-height: 100%;
		margin-inline: -( $grid-gutter-width * .5 );
		padding-inline: ( $grid-gutter-width * .5 );
		// max-width: $content-inner-max-width;

		// Media queries
		@include media-breakpoint-up(sm) {
			margin-inline: -$grid-gutter-width;
		}
		@include media-breakpoint-up(lg) {
			flex-grow: 1;
		}

		&--without-shadow {
			box-shadow: none;
		}

		.row:not(:only-child):last-child {
			.content-box {
				&:last-child {
					padding-block-end: ( $grid-gutter-width * 1.5 );
				}

				// Media queries
				@include media-breakpoint-down(xs) {
					&:last-child {
						border-block-end: none;
					}
				}
				@include media-breakpoint-up(sm) {
					border-block-end: none;
				}
			}
		}

		.row:only-child {
			.content-box {
				// Media queries
				@include media-breakpoint-up(sm) {
					border-block-end: none;
				}

				&:last-child {
					border-block-end: none;
					padding-block-end: ( $grid-gutter-width * 1.5 );

					// Media queries
					@include media-breakpoint-up(sm) {
						border-block-end: none;
					}
				}
			}
		}
	}

	&-divider {
		height: 1px;
		margin: logical 0 ( -$grid-gutter-width * .5 );
		background-color: $theme-gray-300;
		box-shadow: none;
		border: none;

		&--full {
			width: 100%;

			// Media queries
			@include media-breakpoint-up(sm) {
				margin: logical 0 ( -$grid-gutter-width );
			}
		}
	}

	&-box {
		padding-block: ( $grid-gutter-width * .75 );
		padding-inline: ( $grid-gutter-width * .5 );

		&:not(:only-child) {
			border-block-end: 1px solid $theme-gray-300;
		}

		// Media queries
		@include media-breakpoint-up(sm) {
			padding: $grid-gutter-width;
		}

		& + & {
			border-inline-start: 1px solid $theme-gray-300;
		}

		& > .panel {
			height: auto;
		}
	}

	& > .container-fluid {
		// Media queries
		@include media-breakpoint-up(lg) {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
	}
}
