$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Card Radio
 */


.card-radio {
	display: block;
	margin-block-end: ( $grid-gutter-width * .5 );

	// Media queries
	@include media-breakpoint-up(md) {
		margin-block-end: $grid-gutter-width;
	}

	&__inner {
		position: relative;
		// Needs to be separate, logical padding is not working correctly with postcss plugin
		padding-block: $card-padding-mobile-y ( $card-padding-mobile-y * 1.5 );
		padding-inline: ( $card-padding-mobile-x * 1.5 ) $card-padding-mobile-x;
		width: 100%;
		background-color: $card-bg;
		border-radius: $border-radius;
		cursor: pointer;
		overflow: hidden;
		background-position: right bottom;
		background-repeat: no-repeat;
		box-shadow: $card-box-shadow;
		transition:
			transform $transition-speed $transition-easing,
			box-shadow $transition-speed $transition-easing;

		&:hover {
			transform: translateY(-5px);
			box-shadow: $card-hover-box-shadow;
		}

		&:dir(rtl) {
			background-position: left bottom;
		}

		// Media queries
		@include media-breakpoint-up(sm) {
			// Needs to be separate, logical padding is not working correctly with postcss plugin
			padding-block: $card-padding-y;
			padding-inline: $card-padding-x;
			min-height: 220px;
		}
	}

	&__icon {
		position: absolute;
		inset-block-end: 0;
		inset-inline-end: 0;
		margin-block-end: 0;
		width: auto;
		max-height: $card-icon-mobile-height;

		// Media queries
		@include media-breakpoint-up(sm) {
			max-height: $card-icon-height;
		}
	}

	&__title {
		position: relative;
		font-size: $card-title-size;
		font-weight: 600;

		&::before {
			content: '';
			position: absolute;
			inset-block-start: ( $card-title-size * $line-height-base - $custom-control-indicator-size ) / 2;
			inset-inline-start: -(( $spacer * .75 ) + $custom-control-indicator-size );
			width: $custom-control-indicator-size;
			height: $custom-control-indicator-size;
			display: block;
			pointer-events: none;
			background-color: $custom-control-indicator-bg;
			border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
			box-shadow: $custom-control-indicator-box-shadow;
			border-radius: $custom-radio-indicator-border-radius;
			transition: all $transition-speed $transition-easing;

			// Media queries
			@include media-breakpoint-up(sm) {
				inset-inline-start: -( $spacer + $custom-control-indicator-size );
			}
		}

		&::after {
			content: '';
			position: absolute;
			inset-block-start: ( $card-title-size * $line-height-base - $custom-control-indicator-size ) / 2;
			inset-inline-start: -(( $spacer * .75 ) + $custom-control-indicator-size );
			display: block;
			width: $custom-control-indicator-size;
			height: $custom-control-indicator-size;
			background: no-repeat 50% / 70% 70%;

			// Media queries
			@include media-breakpoint-up(sm) {
				inset-inline-start: -( $spacer + $custom-control-indicator-size );
			}
		}
	}

	&__list,
	&__title {
		margin-inline-start: ( $spacer * 1.5 );

		// Media queries
		@include media-breakpoint-up(sm) {
			margin-inline-start: ( $spacer * 2 );
		}
	}

	&__list {
		padding-inline-start: 0;
		list-style-type: none;
		color: $info;

		&-item {
			position: relative;
			padding-inline-start: ( $spacer * .5 );

			&::before {
				content: '\2022';
				position: absolute;
				inset-inline-start: ( $spacer * .25 );
				transform: translateX(-50%);

				&:dir(rtl) {
					transform: translateX(50%);
				}
			}
		}
	}
}

.card-radio__input {
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;

	&:checked + .card-radio__inner,
	&:focus + .card-radio__inner {
		transform: translateY(-5px);
		box-shadow: $card-hover-box-shadow;

		.card-radio__title::before {
			color: $custom-control-indicator-checked-color;
			border-color: $custom-control-indicator-checked-border-color;
			background-color: $input-focused-bg;
			box-shadow: $custom-control-indicator-checked-box-shadow;
		}

		.card-radio__title::after {
			background-image: $custom-radio-indicator-icon-checked;
		}
	}
}