$env-myci-instance: stp;
@import '../../scss/core';
@import '~bootstrap/scss/breadcrumb';

/*
 -  Form control
 -  (<nav class="breadcrumbs" />)
 */


.breadcrumb-item {
	a {
		color: $breadcrumb-color;
	}

	// There is slash after breadcrumb by default, not before
	+ .breadcrumb-item {
		&::before {
			content: none;
		}
	}

	&::after {
		content: $breadcrumb-divider;
		display: inline-block;
		padding-inline-start: $breadcrumb-item-padding;
		color: $breadcrumb-divider-color;
	}

	&.active {
		&::after {
			content: none;
		}
	}
}
