$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Multiselect
 -  <select multiple />
 */


.multiselect {
	position: absolute;
	width: 100%;
	height: $menu-max-height + 66px;
	background-color: $menu-bg;
	box-shadow: $box-shadow-dropdown;
	border-radius: 0 0 $border-radius $border-radius;
	z-index: $zindex-popover;

	.menu {
		box-shadow: none;
	}
}

.multiselect__search {
	padding: logical 0 $spacer 0 ( $spacer * .5 );
	display: flex;
	align-items: center;
	border-block-end: 1px solid lighten($theme-gray-350, 1%);
	background-color: $input-bg;

	&::before {
		@include icon('search');
		font-size: 2rem;
	}

	.form-control[type='text'] {
		border: none;
		background-color: transparent;
		background: none;
		padding-inline-start: ( $spacer * .5 );

		&:focus {
			box-shadow: none;
		}
	}
}

.multiselect__confirm {
	position: absolute;
	width: 100%;
	inset-block-end: 0;
	inset-inline-start: 0;
}