$env-myci-instance: stp;
@import '../../scss/core';
@import '../Menu/Menu.scss';

/*
 -  Popup menu
 */


.popup {
	position: relative;
	min-width: 250px;
	width: auto;
	padding: logical 0;
	margin: logical 10px 0;
	z-index: $zindex-popover;

	&--size-sm {
		min-width: initial;
	}

	&__arrow {
		position: absolute;
		width: 30px;
		height: 10px;

		&[data-placement*='bottom'] {
			inset-block-start: 0;
			margin-block-start: -10px;
			// position of an arrow is computed by JS

			&::before {
				border-width: 0 15px 10px;
				border-color: transparent transparent $dropdown-bg;
			}
		}

		&[data-placement*='top'] {
			inset-block-end: 0;
			margin-block-end: -10px;
			// position of an arrow is computed by JS

			&::before {
				border-width: 10px 10px 0;
				border-color: $dropdown-bg transparent transparent;
			}
		}

		&[data-placement*='right'] {
			inset-inline-end: 0;
			margin-inline-start: -10px;

			&::before {
				border-width: 10px 20px 10px 0;
				border-color: transparent $dropdown-bg transparent transparent;
			}
		}

		&[data-placement*='left'] {
			inset-inline-start: 0;
			margin-inline-end: -10px;

			&::before {
				border-width: 10px 0 10px 20px;
				border-color: transparent transparent transparent $dropdown-bg;
			}
		}

		&::before {
			content: '';
			margin: logical auto;
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
		}
	}
}

.popup-menu {
	box-shadow: $box-shadow-dropdown;
	background-color: $dropdown-bg;
	border-radius: $border-radius;

	.menu {
		position: static;
		padding: logical ( $spacer * .5 ) 0;
		margin: 0;
		border-radius: $border-radius;
		box-shadow: none;
	}

	&__sort {
		padding: logical ( $spacer * .75 ) $spacer ( $spacer * .75 ) ( $spacer * .5 );

		&:not(:only-child) {
			border-bottom: 1px solid lighten($theme-gray-350, 1%);
		}

		.btn {
			margin-inline-start: ( $spacer * .25 );
			min-width: auto;
			padding: logical .5rem;
			font-size: 1.2rem;

			[class^='icon'],
			[class*='icon'] {
				padding-inline-end: 0;
			}

			&:hover,
			&:focus {
				[class^='icon'],
				[class*='icon'] {
					// Color needs to be !important to overwrite utility classes
					// stylelint-disable-next-line
					color: $body-color !important;
				}
			}
		}
	}

	&__search {
		padding: logical 0 $spacer 0 ( $spacer * .5 );
		display: flex;
		align-items: center;
		border-bottom: 1px solid lighten($theme-gray-350, 1%);

		&::before {
			@include icon('search');
			font-size: 2rem;
		}

		.form-control[type='text'] {
			border: none;
			background-color: transparent;
			padding-inline-start: ( $spacer * .5 );

			&:focus {
				box-shadow: none;
			}
		}
	}
}
