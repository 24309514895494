$env-myci-instance: stp;

@import '../../scss/core';
@import '~react-id-swiper/lib/styles/scss/swiper.scss';

/*
 -  Carousel
 */


.carousel__img {
	max-width: 80%;
	box-shadow: 0 8px 20px 0 rgba($theme-black, .05), 0 20px 80px 0 rgba($theme-black, .1);

	// Media queries
	@include media-breakpoint-up(md) {
		max-width: 760px;
	}
}
