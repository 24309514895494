$env-myci-instance: stp;
@import '../../scss/core';

.panel__grid {
	display: flex;

	&--scroll {
		overflow-x: auto;
	}

	&--even {
		flex-wrap: wrap;
	}

	&--odd {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}

.panel .panel__grid {
	margin-inline: -( $grid-gutter-width * .5 );
}
