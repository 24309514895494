$env-myci-instance: stp;
@import '../../scss/core';

.tab-bar {
	display: flex;
	overflow-x: auto;
}
.tab-bar--with-more-tabs::before {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 3px 0 3px 6px;
	border-color: transparent transparent transparent $tab-bar-after-bg;
	position: absolute;
	right: 6px;
	margin-top: 10px;
}