$env-myci-instance: stp;
@import '../../scss/core';

.notification-box {
	border: 1px solid transparent;

	&--kind-info {
		background-color: $notification-box-info-bg;
		border-color: $notification-box-info-border-color;
	}

	&--kind-success {
		background-color: $notification-box-success-bg;
		border-color: $notification-box-success-border-color;
	}

	&--kind-warning {
		background-color: $notification-box-warning-bg;
		border-color: $notification-box-warning-border-color;
	}
}
