$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Journey stepper
 */


.progress-circle-holder {
	position: relative;
	display: inline-flex;
}

.progress-circle {
	width: $progress-circle-size;
	height: $progress-circle-size;

	&__bar,
	&__bg {
		fill: none;
		stroke-width: $progress-circle-stroke-width;
	}

	&__bar {
		stroke: $progress-circle-bar-color;
	}

	&__bg {
		stroke: $progress-circle-bg-color;
	}

	&__status {
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: 50%;
		transform: translate(-50%, -50%);
		font-weight: 700;
		line-height: 1;
	}
}

.stepper__dropdown {
	.dropdown__selected {
		font-weight: 800;
		text-transform: none;
		color: $tab-link-color;
		font-size: $tab-link-font-size-mobile;

		// Media queries
		@include media-breakpoint-up(md) {
			font-size: $tab-link-font-size;
		}
	}
}

[dir='rtl'] {
	.progress-circle__status {
		transform: translate(50%, -50%);
	}
}