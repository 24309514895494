$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Code block
 */

.code-block {
	border: 1px solid $code-block-border-color;
	border-radius: $code-block-border-radius;
	margin-block-end: $spacer;

	&__header,
	&__body {
		white-space: pre-wrap;
		word-break: break-all;
		padding: ( $spacer / 2 );
	}

	&__header {
		background-color: $code-block-header-bg;
	}

	&__body {
		background-color: $code-block-body-bg;
		border-radius: 0 0 $code-block-border-radius $code-block-border-radius;
		min-height: ( $spacer * 10 );
	}
}