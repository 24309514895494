$env-myci-instance: stp;
@import '../../scss/core';

/*
 -  Dashboard
 -  (<a class="panel" href="*" />)
 -  (<div class="panel" />)
 */


.panel {
	width: 100%;
	padding: logical $panel-padding-y-mobile $panel-padding-x-mobile;
	background-color: $panel-bg;
	border-radius: $border-radius;

	&.panel--transparent {
		background-color: transparent;
		padding-inline: 0;

		// Media queries
		@include media-breakpoint-up(xl) {
			padding-inline: 0;
		}
	}

	// Media queries
	@include media-breakpoint-up(xl) {
		padding: logical $panel-padding-y $panel-padding-x;
	}

	&__title {
		display: flex;
		align-items: baseline;
		font-size: $panel-title-font-size;
		font-weight: 400;
		margin-block-end: ( $spacer * 1.25 );
		padding-inline-end: 1rem;

		// Media queries
		@include media-breakpoint-up(md) {
			padding-inline-end: 0;
		}
		@include media-breakpoint-up(lg) {
			&.panel__title--aside {
				margin-block-end: 0;
			}
		}
	}

	&__title-icon {
		position: relative;
		// offset icon a little, baseline is too high
		top: 3px;

		// Media queries
		@include media-breakpoint-down(sm) {
			// stylelint-disable-next-line
			font-size: 1.8rem !important;
		}
	}

	&__link-icon {
		position: absolute;
		inset-block-start: ( $panel-padding-y-mobile + ( $panel-title-icon-size / 2 ));
		inset-inline-end: ( $panel-padding-x-mobile - .5rem );
		transform: translateY(-50%);
		transition: color $transition-speed $transition-easing;

		// Media queries
		@include media-breakpoint-up(xl) {
			inset-block-start: ( $panel-padding-y + ( $panel-title-icon-size / 2 ));
			inset-inline-end: $panel-padding-x;
		}
	}

	&__gauge {
		position: absolute;
		inset-block-start: -18px;
		inset-inline-start: 50%;
		transform: translateX(-50%);

		// Media queries
		@include media-breakpoint-up(md) {
			inset-block-start: -25px;
		}
	}

	&__value {
		position: relative;
		display: inline-flex;
		align-items: center;
		font-weight: 800;
		font-size: $panel-value-size;
		text-align: center;

		// Media queries
		@include media-breakpoint-down(lg) {
			font-size: 2.4rem;
		}
		@include media-breakpoint-down(md) {
			font-size: 2rem;
		}
		@include media-breakpoint-down(sm) {
			font-size: 1.8rem;
		}

		&-icon {
			position: relative;
			margin-inline: -( $spacer * .5 ) ( $spacer * .25 );

			// Media queries
			@include media-breakpoint-down(sm) {
				// stylelint-disable-next-line
				font-size: 1.4rem !important;
			}
			@include media-breakpoint-up(lg) {
				margin-right: ( $spacer * .375 );
			}
		}
	}

	&__desc {
		color: $info;
		text-align: center;

		// Media queries
		@include media-breakpoint-down(sm) {
			font-size: 1.2rem;
		}
	}

	&__image {
		position: absolute;
		inset-block-end: -2rem;
		inset-inline-end: 0;
		height: $panel-image-size;
		margin-block-end: 0;
	}

	&--inactive {
		background-color: $panel-bg-inactive;
	}

	&--active {
		background-color: $panel-bg-active;
		box-shadow: $panel-box-shadow-active;
	}

	.data-list,
	.data-list__heading {
		// Media queries
		@include media-breakpoint-up(xl) {
			padding-inline-start: (2.2rem + 2rem);
		}
	}
}

[dir='rtl'] {
	.panel__gauge {
		transform: translateX(50%);
	}
	.panel__link-icon {
		transform: translateY(-50%) rotate(180deg);
	}
}

a.panel {
	position: relative;
	display: block;
	color: $body-color;

	&--no-title {
		padding-inline-end: ( $panel-padding-x-mobile + 2.5rem );

		// Media queries
		@include media-breakpoint-up(xl) {
			padding-inline-end: ( $panel-padding-x + 3rem );
		}
	}

	&,
	&:visited {
		cursor: pointer;
		text-decoration: none;
		transition:
			transform $transition-speed $transition-easing,
			box-shadow $transition-speed $transition-easing;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: $body-color;
		box-shadow: $panel-box-shadow-hover;
		transform: translateY(-3px);

		.panel__link-icon {
			// !important to overwrite utility classes
			// stylelint-disable-next-line
			color: $panel-icon-hover-color !important;
		}
	}
}